<template>
  <v-app id="driverApp">

    <v-app-bar app  style="background:rgb(235 255 255)"  :style="lang=='ar'?'direction:rtl;':'direction:ltr;'">
      <v-row>
        <v-col md="12">
          <div>
            <div class="d-flex align-center" :class="lang == 'ar'?'float-left ml-3': 'float-right mr-3'">
              <v-menu top left dense>
                <template v-slot:activator="{ on, attrs }">
                  
                  <img style="cursor:pointer;" @click="changeLang('ar')" width="40" height="40" v-if="lang == 'en'" src="/assets/ar.png" alt="">
                  <img style="cursor:pointer;" @click="changeLang('en')" width="40" height="40" v-else src="/assets/en.png" alt="">

                  <p v-bind="attrs" v-on="on" class="mx-3 mt-4 clr-primary">
                    {{ user.admin.name }}
                  </p>

                  <!-- <v-img
                    v-bind="attrs"
                    v-on="on"
                    class="clickable"
                    :src="user.image"
                    style="
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                      margin-left: auto;
                      margin-right: auto;
                      vertical-align: middle;
                    "
                  /> -->
                </template>
                <v-list dense>
                  <!-- <v-list-item dense>
                    <v-btn small color="success" text
                      >{{ $t("Profile") }}
                      <v-icon class="my-0 mr-4" dense>mdi-account</v-icon>
                    </v-btn>
                  </v-list-item> -->
                  <v-list-item dense>
                    <v-btn small color="danger" @click="logout" text
                      >{{ $t("Logout") }}

                      <v-icon class="my-0 mr-7" dense>mdi-logout</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import config from "@/config";
export default {
  name: "App",
  data: () => ({
    lang: localStorage.getItem('lang') || 'ar',
    drawer: true,
    config: config,
    user: localStorage.getItem("driver")
      ? JSON.parse(localStorage.getItem("driver"))
      : [],
  }),
  methods: {
    changeLang(lang) {
      localStorage.setItem('lang', lang);
      window.location.reload();
    },
    goTo(page) {
      window.open(page)
    },
    logout() {
      // this.$store.dispatch("auth/logoutDriver", this.object).then((result) => {
        this.notifySuccess("Logout", "Logout success");
        this.logoutDriverSuccess();
      // }).catch(err => {
        //  this.logoutDriverSuccess();
      // })
    },
  },
};
</script>
